ko.bindingHandlers.recommendationCarusel = {
    update: function (element, valueAccessor, allBindings, viewmodel, context) {
        var object = valueAccessor();

        var initializedSwiper = context.__swiper,
            slidesPerView = ko.unwrap(object.slidesPerView),
            numberOfSlides = ko.unwrap(object.numberOfSlides),
            ready = ko.unwrap(object.ready);

        if (initializedSwiper) {
            initializedSwiper.destroy(false, true);
        }

        if (slidesPerView > 0 && numberOfSlides > 0 && ready) {
            var allowSwipe = object.allowSwipe();
            var previousButton = element.parentNode.querySelector(
                "[data-previous-button]"
            );
            var nextButton = element.parentNode.querySelector(
                "[data-next-button]"
            );
            var direction = ko.unwrap(object.direction) || "horizontal";

            context.__swiper = new Swiper(element, {
                direction: direction,
                navigation: {
                    prevEl: previousButton,
                    nextEl: nextButton,
                },
                loop: false,
                slidesPerView: slidesPerView,
                slidesPerGroup: slidesPerView,
                allowSlideNext: allowSwipe,
                allowSlidePrev: allowSwipe,
                simulateTouch: object.simulateTouch || numberOfSlides > 1,
                on: {
                    init: onInit,
                },
            });

            var destroyableSwiper = context.__swiper;

            destroyableSwiper.on("transitionStart", onTransitionStart);

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                destroyableSwiper.destroy(false, true);
            });
        }

        function onInit(swiper) {
            object.setSlideState(swiper.isBeginning, swiper.isEnd);
            viewmodel.isCaruselRendered(true);
        }

        function onTransitionStart(swiper) {
            object.setSlideState(swiper.isBeginning, swiper.isEnd);
        }
    },
};
