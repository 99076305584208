ko.bindingHandlers.scrollToTop = {
    init: function (element) {
        function scroll() {
            var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

            if (isSmoothScrollSupported) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                window.scrollTo(0, 0);
            }
        }
        ko.applyBindingsToNode(element, { click: scroll });
    }
};