
    ko.bindingHandlers.buttonLoader = {
        update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var showLoader = ko.unwrap(valueAccessor());
            ko.bindingHandlers.css.update(element, function () { return { 'with-loader': showLoader } });

            function loaderRadius(element) {
                if (element.classList.contains("btn-small")) {
                    return 7;
                } else if (element.classList.contains("btn-medium")) {
                    return 10;
                } else {
                    return 12;
                }
            }

            function parseHTML(str) {
                var tmp = document.implementation.createHTMLDocument();
                tmp.body.innerHTML = str;
                return tmp.body.children;
            };

            var buttonLoader = element.querySelector('.button-loader');
            if (showLoader && !buttonLoader) {
                var buttonLoaderElement = parseHTML(
                '<span class="button-loader">' +
                    '<svg class="circular">' +
                        '<circle class="path" cx="15" cy="15" r="' + loaderRadius(element) + '" />' +
                    '</svg>' +
                '</span>')[0];
                element.appendChild(buttonLoaderElement);
            } else if (!showLoader && buttonLoader) {
                element.removeChild(buttonLoader);
            }
        }
    };

