ko.bindingHandlers.uboLoginDefaultPositioning = {
    init: function (element, valueAccessor, allBindings) {
        if (ko.bindingHandlers.uboLoginDefaultPositioning.queryString("view") == "login") {
            var clickFunction = allBindings().click;
            clickFunction(null, null, element);
        }
    },
    queryString: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);

        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
};