(function (ko) {

    var _keyTrapWrapper = {};

    ko.bindingHandlers.keyTrap = {
        init: function (element) {
            var range = document.createRange();
            var parse = range.createContextualFragment.bind(range);
            var beforeSpans = parse('<span tabindex=0></span><span tabindex=0></span>');
            var afterSpans = parse('<span tabindex=0></span><span tabindex=0></span>');

            _keyTrapWrapper.beforeChildren = Array.prototype.slice.call(beforeSpans.children);
            _keyTrapWrapper.afterChildren = Array.prototype.slice.call(afterSpans.children);
        },
        update: function (element, valueAccessor) {
            var value = typeof valueAccessor === 'function' ? valueAccessor() : valueAccessor().keyTrap();

            if (ko.unwrap(value)) {
                var selectedObj = typeof valueAccessor().scope === 'undefined' ? element : element.querySelector(valueAccessor().scope);

                _keyTrapWrapper.beforeChildren.forEach(function (child) { selectedObj.insertBefore(child, selectedObj.firstChild); });
                _keyTrapWrapper.afterChildren.forEach(function (child) { selectedObj.appendChild(child); });

                _keyTrapWrapper.beforeChildren[0].addEventListener('focus', function () {
                    _keyTrapWrapper.beforeChildren[0].focus();
                });
                _keyTrapWrapper.afterChildren[1].addEventListener('focus', function () {
                    _keyTrapWrapper.afterChildren[1].focus();
                });

                if (typeof valueAccessor().scope === 'undefined') {
                    _keyTrapWrapper.beforeChildren[1].focus();
                }
            } else if (_keyTrapWrapper.beforeChildren[0].parentNode) {
                _keyTrapWrapper.beforeChildren.concat(_keyTrapWrapper.afterChildren).forEach(
                    function (child) {
                        if (element.contains(child)) { element.removeChild(child); }
                    });
            }
        },
        renderedHandler: function () {
            _keyTrapWrapper.beforeChildren[1].focus();
        }
    };
})(ko);