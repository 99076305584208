(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('ksc-ko-extenders', ['knockout'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, ko ) {
            if ( ko === undefined ) {
                if ( typeof window !== 'undefined' ) {
                    ko = require('knockout');
                }
                else {
                    ko = require('knockout')(root);
                }
            }
            factory(ko);
        };
    } else {
        // Browser globals
        factory(ko);
    }
}(function (ko) {
    "use strict";

    /**
     *
     * @param {number} [throttleTime]
     * @param {Function} subscriber
     */
    ko.subscribable.fn.throttledSubscribe = function throttledSubscribe(throttleTime, subscriber) {
        var subscriberFn = subscriber;
        var throttleInterval = throttleTime;
        if (!subscriber) {
            subscriberFn = throttleTime;
            throttleInterval = 1;
        }

        var target = this;

        var updating = false;
        target.disregardNextUpdate = function () {
            updating = true;
        };
        target.updateWithoutNotifyingSubscriber = function (value) {
            if (target() === value) {
                return;
            }
            updating = true;
            target(value);
        };

        var throttledComputed = ko.computed(target).extend({ throttle: throttleInterval });

        throttledComputed.subscribe(function (value) {
            if (updating === false) {
                subscriberFn(value);
            } else {
                updating = false;
            }
        });
    };


    /**
     * Throttle the value when it changes from true to false only.
     * When the observable changes from false to true it will update instantly,
     * but when the observable changes from true to false the value will be delayed.
     * @param {number} throttleTime
     * @returns {any} ko.computed
     */
    ko.subscribable.fn.throttleFalsing = function (throttleTime) {
        var target = this;

        var throttled = ko.computed(target).extend({ throttle: throttleTime });

        return ko.computed(function () {
            return target() || throttled();
        });
    };


    /**
     * @param {Array} list
     * @param {Function} selector
     * @param {Function} updater
     * @param {Function} creator
     */
    ko.observableArray.fn.replaceWithDiff = function (list, selector, updater, creator) {
        var existingFacets = this().map(selector);

        this(list.map(function (entry) {
            var index = existingFacets.indexOf(selector(entry));
            if (index >= 0) {
                var existing = this()[index];
                updater(existing, entry);
                return existing;
            }
            return creator(entry);
        }.bind(this)));
    };
}));


