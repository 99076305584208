/**
Check if you have clicked outside the element
- It will set the observable to false if the element is blurred
- It will set the ovservable to true if the element gets focus

Usage: <element data-bind='onBlurObserver:observable'>

*/
ko.bindingHandlers.onBlurObserver = {
    init: function (element, valueAccessor) {

        element.addEventListener("focus", function () {
            setTimeout(function () {
                valueAccessor()(true);
            }, 0);
        });

        element.addEventListener("blur", function () {
            valueAccessor()(false);
        });
    }
};