var metaVersion = document.querySelector("meta[name='version']");
var baseUrl = "/";
var urlArgs = "";

if (metaVersion) {
    urlArgs = "v=" + metaVersion.content;
}

require.config({
    baseUrl: baseUrl,
    waitSeconds: 30000,
    paths: {
        text: "bower_components/text/text",
        async: "JScript/Common/async",
        // bower components
        NumericStringFormat:
            "bower_components/ksc-numeric-string-format/dist/bundle",
        "komplett-pubsub": "bower_components/komplett-pubsub/src/pubsub",
        "ksc-breakpoint-notifier":
            "bower_components/ksc-breakpoint-notifier/dist/js/main",
        "ksc-concurrency-protector":
            "bower_components/ksc-concurrency-protector/concurrencyProtector",
        "ksc-ko-extenders": "bower_components/ksc-ko-extenders/index",
        "ksc-focus-on-element":
            "bower_components/ksc-focus-on-element/focusOnElement",
        "ksc-alert": "bower_components/ksc-alert/dist/js/main",
        "ksc-alert/ViewModel": "bower_components/ksc-alert/dist/js/main",
        "ksc-ubo": "bower_components/ksc-ubo/dist/js/main",
        "ksc-ubo/uboService": "bower_components/ksc-ubo/dist/js/main",
        "ksc-interaction-tracking": "bower_components/ksc-interaction-tracking/dist/js/main",
        knockout: "JScript/Shims/knockout-shim",
        querystring: "JScript/Shims/querystring-shim",
        dataLayer: "JScript/Shims/datalayer-shim",
    },
    urlArgs: urlArgs,
});