ko.bindingHandlers.quantityPickerLength = {
    update: function (element, valueAccessor) {
        var minDigitsNumber = 3;
        var widthFactor = 9;
        var unwrappedValue = ko.utils.unwrapObservable(valueAccessor());
        var numberOfDigits = unwrappedValue.toString().length;
        var widthValue = numberOfDigits < minDigitsNumber ? minDigitsNumber : numberOfDigits;
        var newWidth = (widthValue * widthFactor) + "px";

        element.style.width = newWidth;
    }
};