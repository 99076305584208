ko.bindingHandlers.measureHeight = {
    update: function (element, valueAccessor) {
        var value = valueAccessor().value;
        var withDelay = valueAccessor().withDelay;

        if (withDelay) {
            setTimeout(function () {
                value(element.clientHeight);
            }, 0);
        } else {
            value(element.clientHeight);
        }
    }
};