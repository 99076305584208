ko.bindingHandlers.clearAttributeWhenTrue = {
    update: function (element, valueAccessor) {
        var params = valueAccessor(),
            clearAttribute = ko.unwrap(params.clearAttribute),
            attributeName = ko.unwrap(params.attributeName);

        if (clearAttribute) {
            element.removeAttribute(attributeName);
        }
    }
};