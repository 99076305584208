ko.bindingHandlers.cacheBustedBack = {
    init: function (element) {
        element.addEventListener("click", function (e) {
            e.preventDefault();
            if (document.referrer.indexOf("checkout") > -1) {
                location.href = "/";
            } else {
                if (document.referrer.indexOf('?') == -1) {
                    location.href = document.referrer + '?t=' + Date.now();
                } else {
                    location.href = document.referrer + '&t=' + Date.now();
                }
            }
        });
    }
};