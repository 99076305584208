ko.bindingHandlers.sendBeaconOnClick = {
    init: function (element, valueAccessor) {

        var link = ko.unwrap(valueAccessor());
        if (link && typeof(link) === "object") {
            link = ko.unwrap(link.link);
        }

        element.addEventListener('click', function () {
            navigator.sendBeacon(link);
        }, false);
    }
};