ko.bindingHandlers.scrollEternally = {
    update: function (element, valueAccessor) {
        var params = valueAccessor();
        var isAutoScrollRegistered = params.isAutoScrollRegistered;
        var allowEternalScrolling = params.continue;

        if (allowEternalScrolling() && !isAutoScrollRegistered()) {
            var callback = ko.unwrap(params.callback);
            var extraPixelsAboveElement = 200;

            window.onscroll = function () {
                if (allowEternalScrolling()) {
                    setTimeout(function () {
                        var verticalPosition = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
                        var windowHeight = window.document.documentElement.clientHeight;
                        var scrollBottom = verticalPosition + windowHeight;
                        var elementTop = window.pageYOffset + element.getBoundingClientRect().top - extraPixelsAboveElement;

                        if (scrollBottom > elementTop) {
                            callback(ko.dataFor(element));
                        }
                    }, 1);
                }
            };

            isAutoScrollRegistered(true);
        }
    }
};
