ko.bindingHandlers.productThumbCaruselCalculator = {
    update: function (element, valueAccessor) {

        var object = valueAccessor();
        var itemIdealWidth = 110;
        var maxSlidesPerView = 5;

        function calculate() {
            var width = parseFloat(getComputedStyle(element, null).width.replace("px", ""));
            var slidesPerView = Math.floor(width / itemIdealWidth);
            if (slidesPerView > maxSlidesPerView) {
                slidesPerView = maxSlidesPerView;
            }
            var remainingSpace = width - (slidesPerView * itemIdealWidth);
            object.slidesPerView(slidesPerView);
            object.spaceBetweenSlides(remainingSpace / slidesPerView);
        }

        window.addEventListener('resize', calculate);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            window.removeEventListener('resize', calculate);
        });

        init: {
            calculate();
        }
    }
};