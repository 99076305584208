ko.bindingHandlers.saveWhiteSpaceHeightToLocalStorage = {
    update: function (element, valueAccessor) {
        var object = valueAccessor(),
            ready = ko.unwrap(object.ready),
            imageClassSelector = ko.unwrap(object.imageClassSelector),
            noPlacementReturned = ko.unwrap(object.noPlacementReturned) || false,
            storageName = ko.unwrap(object.localStorageName),
            imageNodeList = element.querySelectorAll(imageClassSelector),
            container;

        if (imageNodeList.length > 0) {
            container = imageNodeList[0];
        }

        function setHeightToLocalStorage() {
            if (noPlacementReturned) {
                element.style.height = "";
                element.style.display = "none";
                localStorage[storageName + location.pathname] = "0";
            }

            if (ready && container) {
                container.addEventListener('load', function () {
                    element.style.height = "";
                    element.style.display = "";
                    element.style.visibility = "inherit";
                    try {
                        var keysToDelete = Object.keys(localStorage).filter(function (key) {
                            return key.indexOf(storageName) !== -1;
                        });
                        if (keysToDelete.length > 9) {
                            for (var i = 0; i < 9; i++) {
                                keysToDelete.splice(Math.floor(Math.random() * keysToDelete.length), 1);
                            }
                            for (var j = 0; j < keysToDelete.length; j++) {
                                localStorage.removeItem(keysToDelete[j]);
                            }
                        }

                        localStorage[storageName + location.pathname] = element.clientHeight + "px";
                    } catch (err) {
                    }
                });
            }
        }

        setHeightToLocalStorage();

        document.addEventListener('fastCartSet', setHeightToLocalStorage);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            document.removeEventListener('fastCartSet', setHeightToLocalStorage);
        });
    }
};