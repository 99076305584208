ko.bindingHandlers.insertHtml = {
    init: function () {
        return { controlsDescendantBindings: true };
    },
    update: function (element, valueAccessor, allBindingsAccessor, parentViewModel) {
        var html = ko.unwrap(valueAccessor()) || "",
            viewModel = ko.unwrap(allBindingsAccessor().viewModel);

        if (html.length == 0) {
            return;
        }

        var virtualElement = document.createElement("div");
        virtualElement.innerHTML = html;

        var nodeList = ko.virtualElements.childNodes(virtualElement);
        var elementList = Array.prototype.slice.call(nodeList);

        ko.virtualElements.setDomNodeChildren(element, elementList);

        viewModel = viewModel || parentViewModel;
        ko.applyBindingsToDescendants(viewModel, element);
    }
};

ko.virtualElements.allowedBindings.insertHtml = true;