/*
ko.bindingHandlers.scrolledOutOfView = {
    init: function (element, valueAccessor) {

        var scrollObservable = valueAccessor();
        var elTop = element.getBoundingClientRect().top;
        var elBottom = element.getBoundingClientRect().bottom;

        var toggleObservable = function(scrollTop, scrollBottom) {
            if (scrollObservable() == null) {
                scrollObservable({ topExceeded: false, bottomExceeded: false });
            }
            var tmpScrollObservable = scrollObservable();

            if (scrollTop < 1 && !scrollObservable().topExceeded) {
                tmpScrollObservable.topExceeded = true;
            } else if (scrollTop >= 1 && scrollObservable().topExceeded) {
                tmpScrollObservable.topExceeded = false;
            }

            if (scrollBottom < 1 && !scrollObservable().bottomExceeded) {
                tmpScrollObservable.bottomExceeded = true;
            } else if (scrollBottom >= 1 && scrollObservable().bottomExceeded) {
                tmpScrollObservable.bottomExceeded = false;
            }

            scrollObservable(tmpScrollObservable);
        };

        var calculateScrolledOutOfView =  ko.computed(function () {
            elTop = element.getBoundingClientRect().top;
            elBottom = element.getBoundingClientRect().bottom;
            toggleObservable(elTop, elBottom);
        }).extend({ throttle: 15 });

        window.addEventListener("scroll", function () {
            scrollObservable.notifySubscribers();
        }, false);

        toggleObservable(elTop, elBottom);
    }
};
*/

ko.bindingHandlers.scrolledOutOfView = {
    init: function (element, valueAccessor) {

        var scrollObservable = valueAccessor();
        var elTop = element.getBoundingClientRect().top;
        var elBottom = element.getBoundingClientRect().bottom;

        toggleObservable(elTop, elBottom);

        var CalculateScrolledOutOfView = function () {
            elTop = element.getBoundingClientRect().top;
            elBottom = element.getBoundingClientRect().bottom;
            toggleObservable(elTop, elBottom);
        };

        var fireCalculationTicker = false;

        function handleScroll() {
            if (!fireCalculationTicker) {
                fireCalculationTicker = true;
                setTimeout(function () {
                    CalculateScrolledOutOfView();
                    fireCalculationTicker = false;
                }, 20);
            }
        }

        window.addEventListener("scroll", handleScroll, false);
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            window.removeEventListener("scroll", handleScroll);
        });

        function toggleObservable(scrollTop, scrollBottom) {
            if (scrollObservable() == null) {
                scrollObservable({ topExceeded: false, bottomExceeded: false });
            }
            var tmpScrollObservable = scrollObservable();

            if (scrollTop < 1 && !scrollObservable().topExceeded) {
                tmpScrollObservable.topExceeded = true;
            } else if (scrollTop >= 1 && scrollObservable().topExceeded) {
                tmpScrollObservable.topExceeded = false;
            }

            if (scrollBottom < 1 && !scrollObservable().bottomExceeded) {
                tmpScrollObservable.bottomExceeded = true;
            } else if (scrollBottom >= 1 && scrollObservable().bottomExceeded) {
                tmpScrollObservable.bottomExceeded = false;
            }

            scrollObservable(tmpScrollObservable);
        }
    }
};
