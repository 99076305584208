ko.bindingHandlers.navigationHeaderCalculator = {
    init: function (element, valueAccessor) {
        var scrollInfo = valueAccessor(),
            previousScrollPosition = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop,
            scrollDirections = {
                up: 'up',
                down: 'down'
            };

        var scrollPosition = scrollInfo.previousScrollPosition,
            localScrollInfo = {
                direction: scrollDirections.down,
                navigationHeadIsOutOfView: false,
                headerHeight: null
            }

        var calculateScrollDirection = function () {
            var direction = scrollDirections.up;
            scrollPosition = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

            if (previousScrollPosition < scrollPosition || scrollPosition === 0) {
                direction = scrollDirections.down;
            }
            else if (previousScrollPosition === scrollPosition) {
                direction = (localScrollInfo.direction === scrollDirections.down) ? scrollDirections.up : scrollDirections.down;
            }

            previousScrollPosition = scrollPosition;
            return direction;
        }

        var navigationHeadIsOutOfView = function () {
            if (localScrollInfo.direction === scrollDirections.down) {
                return (scrollPosition > (element.offsetHeight + element.offsetTop));
            }
            return (scrollPosition > element.offsetTop);
        }

        var scrollCalculator = function () {
            localScrollInfo = {
                direction: calculateScrollDirection(),
                navigationHeadIsOutOfView: navigationHeadIsOutOfView(),
                headerHeight: getHeaderHeight()
            }

            if (JSON.stringify(localScrollInfo) != JSON.stringify(scrollInfo())) {
                scrollInfo(localScrollInfo);
            }
        }

        function getHeaderHeight() {
            return element.firstElementChild.clientHeight + 'px';
        }

        scrollCalculator();

        var onScrollAndResize = (function () {
            var timeout;

            return function () {
                clearTimeout(timeout);
                timeout = setTimeout(scrollCalculator, 20);
            }
        }());

        window.addEventListener("scroll", onScrollAndResize);
        window.addEventListener("resize", onScrollAndResize);
    }
};