ko.bindingHandlers.scrollToHashElementOnClick = {
    init: function (element, valueAccessor) {
        var clickedMenuItemId = ko.unwrap(valueAccessor());

        function scroll() {
            var activeSection = document.getElementById(clickedMenuItemId);
            if (activeSection != null) {
                var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
                
                setTimeout(() => {
                    if (isSmoothScrollSupported) {
                        activeSection.scrollIntoView({behavior: "smooth"});
                    } else {
                        activeSection.scrollIntoView();
                    }
                }, 0);
            }
        }

        ko.applyBindingsToNode(element, { click: scroll });
    }
};