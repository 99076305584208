ko.bindingHandlers.setSearchResultHeight = {
    update: function (element, valueAccessor) {
        function getOffset(el) {
            var box = el.getBoundingClientRect();

            return {
                top:
                    box.top +
                    window.pageYOffset -
                    document.documentElement.clientTop,
                left:
                    box.left +
                    window.pageXOffset -
                    document.documentElement.clientLeft,
            };
        }
        function getInnerHeight(element) {
            var computed = getComputedStyle(element),
                padding =
                    parseInt(computed.paddingTop, 10) +
                    parseInt(computed.paddingBottom, 10);

            return element.clientHeight - padding;
        }
        var values = valueAccessor();
        var shouldMeasure = values.shouldMeasure();
        var isMobile = values.isMobile();

        if (shouldMeasure) {
            if (isMobile) {
                var offset = getOffset(element);
                var top = offset.top;

                element.style.height = "calc(100vh - " + top + "px)";
            } else {
                var currentHeight = getInnerHeight(element);
                element.style.height = "auto";

                var height = getInnerHeight(element);
                if (currentHeight !== 0) {
                    element.style.height = currentHeight;
                }

                if (height !== 0) {
                    element.style.height = height;
                }
            }
        } else {
            element.style.height = "";
        }
    },
};
