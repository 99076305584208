(function () {
    var screenIsXs = false,
        screenIsSm = false;

    require(["ksc-breakpoint-notifier"], function (breakpointNotifier) {
        breakpointNotifier.events.isXs.subscribeTo(function (isXs) {
            screenIsXs = isXs;
        });
        breakpointNotifier.events.isSm.subscribeTo(function (isSm) {
            screenIsSm = isSm;
        });
    });

    ko.bindingHandlers.promoCarusel = {
        update: function (element, valueAccessor) {
            var object = valueAccessor();
            var ready = object.ready;

            if (ko.unwrap(ready)) {
                var pagination = element.parentNode.querySelector(
                    object.pagination
                );
                var nextButton = element.parentNode.querySelector(
                    object.nextButton
                );
                var prevButton = element.parentNode.querySelector(
                    object.prevButton
                );
                var slides =
                    element.parentNode.querySelectorAll(".swiper-slide");

                var onSliderInit = object.onSliderInit;
                var rotationSpeed = element.getAttribute("data-rotation-speed");

                if (!element.swiper) {
                    var swiper = new Swiper(element, {
                        pagination: {
                            el: pagination,
                            type: "bullets",
                            clickable: true,
                        },
                        slidesPerView: "auto",
                        spaceBetween:
                            (screenIsSm || screenIsXs) && slides.length > 1
                                ? 10
                                : 0,
                        navigation: {
                            nextEl: nextButton,
                            prevEl: prevButton,
                        },
                        centeredSlides: true,
                        speed: 500,
                        autoplay: {
                            delay: rotationSpeed * 1000 || 10000,
                            disableOnInteraction: true,
                        },
                        simulateTouch: slides.length > 1,
                        loop: slides.length > 1,
                        on: {
                            init: onSliderInit,
                        },
                        lazy: true,
                    });

                    swiper.on("slideChangeTransitionEnd", onSlideChangeEnd);

                    ko.utils.domNodeDisposal.addDisposeCallback(
                        element,
                        function () {
                            swiper.destroy(false, true);
                        }
                    );

                    object.autoPlay()
                        ? swiper.autoplay.start()
                        : swiper.autoplay.stop();
                }

                if (object.swiperElement) {
                    object.swiperElement({
                        element: element.swiper,
                        update: toggleUpdate,
                    });
                }
            }

            function onSlideChangeEnd() {
                if (object.onSlideChanged) {
                    object.onSlideChanged(getActiveIndex());
                }
            }

            function getActiveIndex() {
                var activeElement = element.querySelector(
                    ".swiper-slide-active"
                );
                if (activeElement) {
                    return parseInt(
                        activeElement.getAttribute("data-swiper-slide-index")
                    );
                }

                return -1;
            }

            function toggleUpdate(swiperElement) {
                var swiper = new Swiper(
                    swiperElement.$el[0],
                    swiperElement.params
                );

                ko.utils.domNodeDisposal.addDisposeCallback(
                    element,
                    function () {
                        swiper.destroy(false, true);
                    }
                );
            }
        },
    };
})();
