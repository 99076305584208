ko.bindingHandlers.productThumbCarusel = {
    update: function (element, valueAccessor) {
        var object = valueAccessor();

        if (!ko.unwrap(object.init)) {
            return;
        }

        var slidesPerView = ko.unwrap(object.slidesPerView);
        var numberOfSlides = ko.unwrap(valueAccessor().numberOfSlides);
        var initializedSwiper = object.initializedSwiper();
        var isSwiper = object.isSwiper;
        var spaceBetweenSlides = ko.unwrap(object.spaceBetweenSlides);

        var applySwipe = numberOfSlides > slidesPerView;

        if (isSwiper) {
            isSwiper(applySwipe);
        }

        if (applySwipe) {
            if (initializedSwiper) {
                initializedSwiper.detachEvents();
                initializedSwiper.destroy(true, true);
                initializedSwiper = null;
            }

            var swiper = new Swiper(element, {
                mousewheelControl: true,
                loop: true,
                slidesPerView: slidesPerView,
                slidesPerGroup: slidesPerView,
                spaceBetween: spaceBetweenSlides,
            });

            swiper.on("tap", function (instance) {
                if (instance.clickedSlide.dataset) {
                    object.clickedIndex(
                        parseInt(instance.clickedSlide.dataset.slideIndex, 10)
                    );
                }
            });

            object.initializedSwiper(swiper);
            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                swiper.destroy(true, true);
            });

        } else {
            if (initializedSwiper) {
                initializedSwiper.params.slidesPerView = initializedSwiper.params.slidesPerGroup = numberOfSlides;
                initializedSwiper.params.spaceBetween = spaceBetweenSlides;
                initializedSwiper.detachEvents();
                initializedSwiper.destroy(false, true);
            } else {
                var slideNodeList = element.querySelectorAll(".swiper-slide");
                for (var i = 0; i < slideNodeList.length; i++) {
                    slideNodeList[i].style.marginLeft = spaceBetweenSlides / 2 + "px";
                    slideNodeList[i].style.marginRight = spaceBetweenSlides / 2 + "px";
                }
            }
        }

        object.init(false);
    },
};
