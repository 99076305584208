/**
Highlights the element if the observable is equal to the value 
Usage: <span data-bind="selected: viewmode, value: 'box'>Box</span>
*/ko.bindingHandlers.selected = {
    update: function (element, valueAccessor, allBindingsAccessor, context) {
        var bindings = allBindingsAccessor(),
            value = valueAccessor(),
            name = ko.utils.unwrapObservable(bindings.value),
            isSelected = { selected: value() == name };

        ko.bindingHandlers.css.update(element, function () { return isSelected; }, allBindingsAccessor, context);
    }
};


/**
Highlights the element if the observable is equal to the value. Click to to set the observable to the value. 
Usage: <span data-bind="selectable: viewmode, value: 'box'>Box</span>
*/
ko.bindingHandlers.selectable = {
    init: function (element, valueAccessor, allBindingsAccessor, viewmodel, context) {
        var bindings = allBindingsAccessor(),
            value = valueAccessor(),
            name = ko.utils.unwrapObservable(bindings.value),
            unselectable = bindings.unselectable,
            setSelected = function () {
                if (unselectable) {
                    value(value() === name ? '' : name);
                } else {
                    value(name);
                }
            };

        ko.bindingHandlers.click.init(element, function () { return setSelected; }, allBindingsAccessor, viewmodel, context);
    },
    update: function (element, valueAccessor, allBindingsAccessor, context) {
        var bindings = allBindingsAccessor(),
            value = valueAccessor(),
            name = ko.utils.unwrapObservable(bindings.value),
            isSelected = { selected: value() == name };

        ko.bindingHandlers.css.update(element,
            function () {
                return isSelected;
            }, allBindingsAccessor, context);
    }
};

