ko.bindingHandlers.forceSelectText = {
    init: function (element) {
        element.style.cursor = 'text';
        element.addEventListener("click", function (event) {
            event.preventDefault();
            ko.bindingHandlers.forceSelectText.selectText(element);
        });
    },
    selectText: function (element) {
        var range, selection;

        if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

};