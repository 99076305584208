ko.bindingHandlers.setAbsolutePosition = {
    update: function (element, valueAccessor) {
        function getOffset(el) {
            var box = el.getBoundingClientRect();

            return {
                top:
                    box.top +
                    window.pageYOffset -
                    document.documentElement.clientTop,
                left:
                    box.left +
                    window.pageXOffset -
                    document.documentElement.clientLeft,
            };
        }
        var windowWidth = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );
        var visible = valueAccessor().visible;

        if (visible()) {
            var name = ko.unwrap(valueAccessor().name);
            var positionOnElement = document.querySelector(
                "[data-absoluteposition='" + name + "']"
            );

            var top = positionOnElement.offsetTop;
            var left = positionOnElement.offsetLeft;
            var right =
                windowWidth -
                getOffset(positionOnElement).left -
                element.offsetWidth;

            if (right < 0) {
                right = 0;
                left = "inherit";
                element.style.left = left;
                element.style.right = `${right}px`;
            } else {
                right = "inherit";
                element.style.left = `${left}px`;
                element.style.right = right;
            }

            element.style.top = `${top}px`;
        }
    },
};
