document.addEventListener('DOMContentLoaded', function () {

    require([
        "JScript/Common/viewModelLoader",
        // KSC Custom Bindings
        "ksc-ko-extenders",
        "bower_components/ksc-ubo/dist/js/custombindings",
        "bower_components/ksc-alert/dist/js/custombindings"
    ], function (viewModelLoader) {
        ko.bindingHandlers.setViewModel = {
            init: function (element, valueAccessor, allBindingsAccessor, parentViewModel, parentContext) {
                var viewModelName = ko.utils.unwrapObservable(valueAccessor());

                viewModelLoader.loadNestedViewModel(element, viewModelName, parentContext);

                return { controlsDescendantBindings: true };
            }
        };

        ko.bindingProvider.instance = (function (originalBindingProvider) {
            return {
                nodeHasBindings: function (node) {
                    return hasViewModel(node) || originalBindingProvider.nodeHasBindings(node);
                },
                getBindingAccessors: function (node, bindingContext) {
                    if (hasViewModel(node)) {
                        return {
                            setViewModel: function () {
                                return node.getAttribute("data-viewmodel") || node.getAttribute("data-lazy-viewmodel");
                            }
                        };
                    }
                    return originalBindingProvider.getBindingAccessors(node, bindingContext);
                },
                nativeBindingProviderInstance: originalBindingProvider
            };

            function hasViewModel(node) {
                return node.nodeType === 1 && (node.hasAttribute("data-viewmodel") || node.hasAttribute("data-lazy-viewmodel")) && !("@ViewModelName" in node);
            }
        })(ko.bindingProvider.instance);

        init: {
            viewModelLoader.loadViewModel();
        }
    });
}, false);