ko.bindingHandlers.intersectObserver = {
    update: function (element, valueAccessor) {
        var params = valueAccessor();
        var options = {
            root: params.root,
            rootMargin: params.rootMargin,
            threshold: params.threshold
        };
        var checkVisible = function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var callback = ko.unwrap(params.callback);
                    callback();
                }
            });
        };
        var observer = new IntersectionObserver(checkVisible, options);
        observer.observe(element);
    }
};
