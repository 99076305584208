ko.bindingHandlers.enableTab = {
    update: function (element) {
        element.onkeydown = function (event) {
            if (event.keyCode === 9) { // tab was pressed
                // prevent the focus lose
                event.preventDefault();

                // get caret position/selection
                var val = this.value,
                    start = this.selectionStart,
                    end = this.selectionEnd;

                // set value to: text before caret + tab + text after caret
                this.value = val.substring(0, start) + '\t' + val.substring(end);

                // put caret at right position again
                this.selectionStart = this.selectionEnd = start + 1;
            }
        };
    }
};