ko.bindingHandlers.horizontalSwiper = {
    update: function (element, valueAccessor) {
        var object = valueAccessor();

        var applySwipe = object.applySwipe || true;

        var previousButton = element.parentNode.querySelector(
            "[data-previous-button]"
        );
        var nextButton = element.parentNode.querySelector("[data-next-button]");

        if (applySwipe) {
            var options = {
                direction: "horizontal",
                slidesPerView: 1,
                slidesPerColumnFill: "row",
                loop: true,
                keyboardControl: object.keyboardControl || true,
                a11y: true,
                simulateTouch: object.simulateTouch || true,
            };

            if (previousButton && nextButton) {
                options.navigation = {
                    nextEl: nextButton,
                    prevEl: previousButton,
                };
            }

            var swiper = new Swiper(element, options);

            var selectedSlide = swiper.slides[0];

            swiper.on("slideChangeTransitionEnd", function () {
                if (swiper.slides[swiper.activeIndex].dataset) {
                    object.activeSlideIndex(
                        parseInt(
                            swiper.slides[swiper.activeIndex].dataset
                                .slideIndex,
                            10
                        ) + 1
                    );
                }
            });

            swiper.on("slideChangeTransitionStart", function (instance) {
                if (object.onSlideChange) {
                    object.onSlideChange({
                        activeSlide: selectedSlide,
                    });
                }
                selectedSlide = instance.slides[instance.activeIndex];
            });

            var duplicates = element.querySelectorAll(
                ".swiper-slide-duplicate"
            );
            for (var i = 0; i < duplicates.length; i++) {
                duplicates[i].setAttribute("tabindex", "-1");
            }

            object.swiper(swiper);

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                swiper.destroy(false, true);
            });
        }
    },
};
