/**
Specific for dropdown navigationlist, which should only have this functionally on md+ screens
Check if you have clicked outside the dropdown
- It will set the observable to false if you click outside the element
- Elements within the element counts as inside
Usage: <element data-bind='clickOutsideDropdown: {
                                                    showNavigationListDropdown: showNavigationListDropdownObservable,
                                                    isLargerThanSmallScreen: isLargerThanSmallScreenObservable
                                                 }'>

*/
ko.bindingHandlers.clickOutsideDropdown = {
    init: function (element, valueAccessor) {
        var object = valueAccessor(),
            value = object.showNavigationListDropdown,
            isLargerThanSmallScreen = object.isLargerThanSmallScreen;

        function getParents(elm) {
            var result = [];
            for (var p = elm && elm.parentElement; p; p = p.parentElement) {
                result.push(p);
            }
            return result;
        }

        element.clickOutsideDropdownHandler = function (e) {
            if (isLargerThanSmallScreen()) {
                if (e.target != element && getParents(e.target).filter(function (index, parent) { return parent == element; }).length == 0) {
                    if (element.contains(e.target)) {
                        return;
                    }
                    value(false);
                }
            }
        }
    },
    update: function (element, valueAccessor) {
        if (valueAccessor().showNavigationListDropdown()) {
            setTimeout(function () {
                var html = document.getElementsByTagName("HTML")[0];
                html.addEventListener("click", element.clickOutsideDropdownHandler);
                html.addEventListener("touchstart", element.clickOutsideDropdownHandler);
            }, 0);
        } else {
            setTimeout(function () {
                var html = document.getElementsByTagName("HTML")[0];
                html.removeEventListener("click", element.clickOutsideDropdownHandler);
                html.removeEventListener("touchstart", element.clickOutsideDropdownHandler);
            }, 0);
        }
    }
};