(function (ko) {
    var preventScroll = function (event) {
        var searchTerms = '.scroll';
        var closestElementWithScroll = event.target.closest(searchTerms);

        if (!closestElementWithScroll) {
            event.preventDefault();
        }
    };

    ko.bindingHandlers.disableScrolling = {
        update: function (element, valueAccessor) {
            var disableScroll = ko.utils.unwrapObservable(valueAccessor());

            if (disableScroll) {
                element.addEventListener('touchmove', preventScroll);
            } else {
                element.removeEventListener('touchmove', preventScroll);
            }
        }
    };
})(ko);