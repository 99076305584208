ko.bindingHandlers.highlightText = {
    update: function (element, valueAccessor) {
        var word = ko.utils.unwrapObservable(valueAccessor()).replace(/[\\*"']/g, "");
        var text = element.innerHTML.replace(/\\"/g, "");
        var index = text.toLowerCase().indexOf(word.toLowerCase());
        var foundText = text.slice(index, index + word.length);
        var rgxp = new RegExp(word, "i");
        var repl = "<span class=\"marked\">" + foundText + "</span>";
        element.innerHTML = element.innerHTML.replace(rgxp, repl);
    }
};

/**
Sets the html inside an element and binds in to a viewmodel
Usage:
<div data-bind="bindHTML: '<img data-bind=\"attr:{src:src}\" />', viewModel: {src:'path/to/image.gif'}"></div>
*/

ko.bindingHandlers.bindHtml = {
    init: function () {
        return { controlsDescendantBindings: true };
    },
    update: function (element, valueAccessor, allBindingsAccessor, parentViewModel) {
        var html = ko.unwrap(valueAccessor());
        var viewModel = ko.unwrap(allBindingsAccessor().viewModel);

        if (html || html === "") {
            ko.virtualElements.emptyNode(element);
            element.innerHTML = html;
        }

        viewModel = viewModel || parentViewModel;
        ko.applyBindingsToDescendants(viewModel, element);
    }
};

ko.bindingHandlers.stopBinding = {
    init: function () {
        return { controlsDescendantBindings: true };
    }
};

ko.bindingHandlers.onKeyUp =
{
    update: function (element, valueAccessor) {
        var object = ko.utils.unwrapObservable(valueAccessor());
        if (ko.utils.unwrapObservable(object.when)) {
            var handler = function (event) {
                if (event.keyCode === object.key) {
                    object.do(event);
                }
            };
            document.removeEventListener("keydown", handler);
            document.addEventListener("keydown", handler);
        } else {
            document.removeEventListener("keydown", handler);
        }
    }
};


ko.bindingHandlers.nonEmptyText = {
    update: function (element, valueAccessor) {
        var text = ko.utils.unwrapObservable(valueAccessor());
        if (text) {
            element.innerText = text;
        }
    }
};

/**
Highlights the element if the observable is equal to the value
Usage: <span data-bind="selected: viewmode, value: 'box'>Box</span>
*/ko.bindingHandlers.selected = {
    update: function (element, valueAccessor, allBindingsAccessor, context) {
        var bindings = allBindingsAccessor();
        var value = valueAccessor();
        var name = ko.utils.unwrapObservable(bindings.value);
        var isSelected = { selected: value() === name };

        ko.bindingHandlers.css.update(element, function () {
            return isSelected;
        }, allBindingsAccessor, context);
    }
};


/**
Highlights the element if the observable is equal to the value. Click to to set the observable to the value.
Usage: <span data-bind="selectable: viewmode, value: 'box'>Box</span>
*/
ko.bindingHandlers.selectable = {
    init: function (element, valueAccessor, allBindingsAccessor, viewmodel, context) {
        var bindings = allBindingsAccessor();
        var value = valueAccessor();
        var name = ko.utils.unwrapObservable(bindings.value);
        var unselectable = bindings.unselectable;
        var setSelected = function () {
            if (unselectable) {
                value(value() === name ? "" : name);
            } else {
                value(name);
            }
        };

        ko.bindingHandlers.click.init(element, function () {
            return setSelected;
        }, allBindingsAccessor, viewmodel, context);
    },
    update: function (element, valueAccessor, allBindingsAccessor, context) {
        var bindings = allBindingsAccessor();
        var value = valueAccessor();
        var name = ko.utils.unwrapObservable(bindings.value);
        var isSelected = { selected: value() === name };

        ko.bindingHandlers.css.update(element,
            function () {
                return isSelected;
            }, allBindingsAccessor, context);
    }
};


ko.bindingHandlers.fadeVisible = {
    init: function (element, valueAccessor) {
        var visible = valueAccessor().visible;

        if (ko.utils.unwrapObservable(visible)) {
            element.style.display = "";
            element.classList.add('fadeShow');
        } else {
            element.style.display = "none";
            element.classList.add('fadeHide');
        }
    },
    update: function (element, valueAccessor) {
        valueAccessor().effect;
        var visible = valueAccessor().visible;

        if (ko.utils.unwrapObservable(visible)) {
            element.style.display = "";
            setTimeout(function () {
                element.classList.add('fadeShow');
                element.classList.remove('fadeHide');
            }, 20);
        } else {
            element.classList.add('fadeHide');
            element.classList.remove('fadeShow');
            setTimeout(function () {
                element.style.display = "none";
            }, 400);
        }
    }
};

// Can be removed when komplett bedrift starts usin Caas header
ko.bindingHandlers.fadeVisibility = {
    init: function (element) {
        element.style.visibility = "visible";
        element.classList.add('bindingFadeIn');
    }
};

ko.bindingHandlers.applyFunction = {
    init: function (element, valueAccessor) {
        valueAccessor();
    }
};

ko.bindingHandlers.afterHtmlRender = {
    update: function (element, valueAccessor, allBindings) {
        if (!allBindings().html) return;
        var callback = valueAccessor();
        callback(allBindings().html);
    }
};

ko.bindingHandlers.slideDown = {
    update: function (element, valueAccessor) {
        var isOpen = ko.utils.unwrapObservable(valueAccessor());
        element.style.maxHeight = isOpen ? element.scrollHeight + "px" : null;
    }
};