ko.bindingHandlers.horizontalSwiperLazyLoad = {
    update: function (element, valueAccessor) {
        var object = valueAccessor();

        var swiper = new Swiper(element, {
            direction: "horizontal",
            pagination: {
                el: object.pagination,
                type: object.paginationType || "bullets",
                clickable: true,
            },
            mousewheelControl: false,
            slidesPerView: 1,
            slidesPerColumnFill: "row",
            navigation: {
                nextEl: object.nextButton,
                prevEl: object.prevButton,
            },
            simulateTouch: object.simulateTouch || false,
            loop: true,
            keyboard: true,
            preloadImages: false,
            zoom: object.zoom || false,
            lazy: {
                loadOnTransitionStart: true,
            },
        });
        swiper.on("lazyImageLoad", function () {
            object.showLoader(true);
        });
        swiper.on("lazyImageReady", function () {
            object.showLoader(false);
        });

        object.swiper(swiper);
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            swiper.destroy(false, true);
        });
    },
};
