/**
Adding a new object with observables to the specified observable array
Usage: <element data-bind='slider: {values: <observable array>, min: <observable int>, max: <observable int>, change: <function>}'>
*/
ko.bindingHandlers.slider = {
    init: function (element, valueAccessor) {
        var data = ko.unwrap(valueAccessor());
        var min = ko.unwrap(data.min);
        var max = ko.unwrap(data.max);

        var previousMin = min, previousMax = max;

        if (min === max) {
            min = 0;
            max = 1;
        }

        noUiSlider.create(element, {
            start: [ko.unwrap(data.minValueWithoutNotify), ko.unwrap(data.maxValueWithoutNotify)],
            connect: true,
            step: 1,
            range: {
                'min': min,
                'max': max
            }
        });

        element.noUiSlider.on('update', function (values) {
            var newMinValue = Math.round(values[0]);
            var newMaxValue = Math.round(values[1]);
            data.minValueWithoutNotify(newMinValue);
            data.maxValueWithoutNotify(newMaxValue);
        });

        element.noUiSlider.on('change', function (values) {
            var minValue = Math.round(values[0]);
            var maxValue = Math.round(values[1]);
            if (minValue !== Math.round(previousMin) || maxValue !== Math.round(previousMax)) {
                previousMin = minValue;
                previousMax = maxValue;
                data.onChange();
            }
        });
    }
};

ko.bindingHandlers.sliderRangeUpdated = {
    update: function(element, valueAccessor) {
        var data = ko.unwrap(valueAccessor());
        var min = ko.unwrap(data.min);
        var max = ko.unwrap(data.max);

        var currentRange = element.noUiSlider.options.range;
        if ((min !== currentRange.min || max !== currentRange.max) && min !== max) {
            element.noUiSlider.updateOptions({ range: { min: min, max: max } });
        }
    }
};

ko.bindingHandlers.sliderMinMaxValueUpdated = {
    update: function(element, valueAccessor) {
        var data = ko.unwrap(valueAccessor());
        var minValue = ko.unwrap(data.minValue);
        var maxValue = ko.unwrap(data.maxValue);

        var currentSliderValue = element.noUiSlider.get();
        if (minValue !== Math.round(currentSliderValue[0]) || maxValue !== Math.round(currentSliderValue[1])) {
            element.noUiSlider.set([minValue, maxValue]);
        }
    }
};

ko.bindingHandlers.sliderKeyHandler = {
    update: function (element, valueAccessor) {
        var data = ko.unwrap(valueAccessor());
        var lowerValue = data.lowerValue;
        var upperValue = data.upperValue;
        var max = ko.unwrap(data.max);
        var step = max * 0.05;

        var handles = Array.prototype.slice.call(element.querySelectorAll('.noUi-handle'));

        handles.forEach(function (handle, index) {
            handle.addEventListener('keydown',
                function (e) {

                    var value = element.noUiSlider.get();
                    var newValue = 0;
                    var currentValue = Number(value[index]);
                    switch (e.which) {
                        case 37:
                            newValue = currentValue - step;
                            break;
                        case 39:
                            newValue = currentValue + step;
                            break;
                    }
                    if (newValue) {
                        index === 0 ? lowerValue(newValue) : upperValue(newValue);
                    }
                });
        });
    }
};